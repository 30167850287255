<template>
  <div class="list_body">
    <div :style="{ minHeight: '5vh' }">位置:一级页面/二级页面</div>
    <div :style="{ padding: '15px', background: '#fff', minHeight: '5vh', display: 'flex' }">
      <!-- 搜索 -->
      <a-form-model layout="inline" @submit="searchSubmit">
        <a-form-model-item label="姓名:">
          <div style="background-color: red; width: 20px; higth: 10px"></div>
          <a-input placeholder="请输入" v-model="searchForm.searchText" :style="{ display: 'inline' }" :allowClear="true" />
        </a-form-model-item>
        <a-form-model-item label="提交日期:">
          <a-range-picker valueFormat="yyyy-MM-DD HH:mm:ss" v-model="dateRange" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :style="{ padding: '10px' }"></div>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '90vh' }">
      <a-button type="primary" @click="showModal"> 新增 </a-button>
      <!-- 新增/修改 -->
      <a-modal title="新增/修改专家智库" :visible="visible" :confirm-loading="confirmLoading" @ok="addOk" @cancel="handleCancel" :mask-closable="false">
        <a-form-model layout="vertical" labelAlign="right" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="照片:">
             <upload-widget v-model="experts.via"></upload-widget>
          </a-form-model-item>
          <a-form-model-item label="姓名:">
            <a-input placeholder="请输入姓名" style="width: 220px" v-model="experts.name" />
          </a-form-model-item>
          <a-form-model-item label="专家职位:">
            <a-input placeholder="请输入专家职位" style="width: 220px" v-model="experts.position" />
          </a-form-model-item>
          <a-form-model-item label="排序值:">
            <a-input placeholder="请输入排序值" style="width: 220px" v-model="experts.orderby" />
          </a-form-model-item>
          <a-form-model-item label="简介:">
            <a-textarea placeholder="请输入" :rows="4" v-model="experts.introduction" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 表格 -->
      <a-table :columns="columns" :data-source="data" :pagination="datapager" @change="handleTableChange" :row-key="(record) => record.id">
        <span slot="num" slot-scope="text, record, index"> {{ (datapager.current - 1) * datapager.pageSize + index + 1 }}</span>
        <!-- <span slot="via" slot-scope="text">{{ text }}</span> -->
        <img :style="{ width: '90px' }" slot="via" slot-scope="text" :src="serverUrl + text" alt="" />
        <span slot="name" slot-scope="text">{{ text }}</span>
        <span slot="position" slot-scope="text">{{ text }}</span>
        <span slot="introduction" slot-scope="text">{{ text }}</span>
        <span slot="orderby" slot-scope="text">{{ text }}</span>
        <span slot="created" slot-scope="text">{{ text }}</span>
        <span slot="action" slot-scope="text, record">
          <a-button @click="showUpdate(record)">编辑</a-button>
          <a-divider type="vertical" />
          <a-button @click="del(record)" type="danger"> 删除 </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
/**表头信息 */
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    scopedSlots: { customRender: 'num' }, //customRender值不能叫title
  },
  {
    title: '头像',
    dataIndex: 'via',
    key: 'via',
    scopedSlots: { customRender: 'via' },
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '专家职位',
    dataIndex: 'position',
    key: 'position',
    scopedSlots: { customRender: 'position' },
  },
  {
    title: '简介',
    dataIndex: 'introduction',
    key: 'introduction',
    width: 250,
    scopedSlots: { customRender: 'introduction' },
  },
  {
    title: '排序',
    key: 'orderby',
    dataIndex: 'orderby',
    scopedSlots: { customRender: 'orderby' },
  },
  {
    title: '创建时间',
    key: 'created',
    dataIndex: 'created',
    scopedSlots: { customRender: 'created' },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

import DateUtils from '@/common/DateUtils';
// import Dashboard from '../Dashboard.vue';
const data = [];
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  //   components: { Dashboard },
  data() {
    return {
      loading: false,
      imageUrl: '',
      addOk: this.handleOk,
      visible: false,
      confirmLoading: false,

      experts: {via:""},
      data, //表格数据，从服务端中获取
      columns, //表头
      dateRange: ['', ''],
      searchForm: { searchText: '', solutionName: '', startTime: '', endTime: '' }, //查询参数
      datapager: { pageSize: 10, total: 100, current: 1, showSizeChanger: true }, //分页参数
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    /**读取列表 */
    async loadList() {
      //调用服务端接口获取文章列表
      let result = await this.axios({
        method: 'post',
        url: `experts/search/${this.datapager.current}/${this.datapager.pageSize}`,
        data: this.searchForm,
      });
      var datum = result.data.data;
      this.datapager.total = result.data.total;
      this.data = JSON.parse(JSON.stringify(datum));
    },
    /**确认查询 */
    searchSubmit(e) {
      this.searchForm.startTime = this.dateRange[0];
      this.searchForm.endTime = this.dateRange[1];
      this.loadList(); //重新读取列表
      e.preventDefault();
    },
    /**分页 */
    handleTableChange(pagination1, filters, sorter) {
      this.datapager = pagination1; //保存分页信息
      this.loadList(); //重新读取文章列表
    },
    /**打开新增 */
    showModal() {
      this.visible = true;
      this.addOk = this.handleOk;
    },
    /**新增确定 */
    async handleOk(e) {
      this.confirmLoading = true;

      try {
        let result = await this.axios({
          method: 'post',
          url: `experts/save`,
          data: this.experts,
        });
        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.experts = {};
      }, 500);
    },
    /**新增取消 */
    handleCancel(e) {
      this.experts = {};
      this.imageUrl = '';
      this.visible = false;
      this.loading = false;
    },
    /**修改 */
    showUpdate(obj) {
      this.visible = true;

      //深拷贝
      this.experts = JSON.parse(JSON.stringify(obj));
      this.imageUrl = this.experts.via;
      this.addOk = this.update;
      console.log(this.experts);
    },
    /**修改 */
    async update() {
      this.confirmLoading = true;
      console.log('修改');
      try {
        let result = await this.axios({
          method: 'post',
          url: `experts/update`,
          data: this.experts,
        });
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.experts = {};
        this.imageUrl = '';
      }, 500);
    },

    /**删除 */
    del(obj) {
      var that = this;
      //删除提示
      this.$confirm({
        title: '提示',
        content: '是否确定删除',
        async onOk() {
          //确定删除
          try {
            let result = await that.axios.delete(`experts/delete/${obj.id}`);
            that.$message.success('删除成功');
            that.loadList();
          } catch (error) {
            that.$message.error('删除失败');
          }
        },
        onCancel() {}, //点击取消按钮后
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.a {
  background-color: aquamarine;
}
</style>
